.emp-info-container,
.emp-transfer-reason-container,
.add-transfer-docs-container {
	width: 100%;
	padding: 2% 0;
	border-bottom: 1px solid #ccc;
}

.emp-current-info,
.emp-transfer-info,
.emp-want-to-transfer-info,
.emp-transfer-reason,
.add-transfer-docs {
	width: 50%;
	box-sizing: border-box;
	margin: 0 20%;
	margin-bottom: 5%;
}

.emp-transfer-reason label {
	font-weight: 700;
}

.emp-transfer-reason textarea {
	width: 100%;
	padding: 12px 20px;
	box-sizing: border-box;
	border-radius: 4px;
	border: 2px solid #ccc;
	font-size: 16px;
	height: 150px;
}

.emp-info-heading {
	margin-bottom: 3%;
	border-bottom: 1px solid #ccc;
	font-size: 20px;
	background-color: #f2f2f2;
}

.emp-current-info .label,
.emp-transfer-info .label {
	float: left;
	font-weight: 700;
}

.emp-current-info .value,
.emp-transfer-info .value {
	float: right;
	color: #009879;
	font-weight: bold;
}

.emp-transfer-details {
	margin-bottom: 5%;
}

.emp-transfer-details-date {
	font-weight: bold;
	color: #009879;
}

.emp-want-to-transfer-college-list {
	font-weight: bold;
}

.emp-want-to-transfer-college {
	color: #009879;
}

.application-container {
	width: 100%;
	background: #f2f2f2;
	padding: 2% 0;
	overflow-y: auto;
	border-bottom: 1px solid #ccc;
	box-sizing: border-box;

}

.application-tempelate {
	width: 50%;
	background: #fff;
	margin: 0 20%;
	padding: 3%;
	overflow-y: auto;
	box-sizing: border-box;

}

@media screen and (max-width: 900px) {

	.application-tempelate {
		width: 100%;
		margin: 0;
	}

	.application-container {
		padding: 2%;
	}
}

.margin-bottom {
	margin-bottom: 40% !important;
}

@media screen and (max-width: 600px) {
	.margin-bottom {
		margin-bottom: 25% !important;
	}
}

.application-txt {
	margin-bottom: 5%;
}

.application-txt .text1 {
	margin-bottom: 5%;
}

.application-txt .text2 {
	text-indent: 10%;
	text-align: justify;
	text-justify: inter-word;
}

.application-sub {
	margin: 5% 0;
}

.application-sub h4 {
	text-indent: 11%;
	text-decoration: underline;
	text-transform: uppercase;
	text-align: justify;
	text-justify: inter-word;
}

.application-footer {
	width: 100%;
}

.application-footer-date {
	float: left;
}

.application-footer-txt {
	float: right;
	text-align: center;
}

.send-application-btn {
	padding: 5%;
}

.application-posting-place-name,
.application-footer {
	text-align: center;
}

.application-footer {
	margin-top: 15%;
}

.application-footer-by {
	float: right;
}

.my-application-container {
	width: 100%;
	background: #f2f2f2;
	padding: 2%;
	overflow-y: auto;
	border-bottom: 1px solid #ccc;
	box-sizing: border-box;

}

.my-application-tempelate {
	width: 100%;
	background: #fff;
	/* padding: 2%; */
	overflow-y: auto;
	box-sizing: border-box;

}

.page {
	background: yellow;
	display: block;
	margin: 0 auto;
	position: relative;
	width: 21cm;
	height: 29.7cm;
	overflow: hidden;
}

.page[size="A4"] {
	width: 21cm;
	height: 29.7cm;
	overflow: hidden;
	box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.my-application-header {
	width: 100%;
	box-sizing: border-box;
	display: flex;
}

.my-application-header-address {
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 10%;
	padding: 0 2%;
}

.application-address {
	margin-left: 5%;
}

.my-application-header-address p {
	font-size: 1vw;
	line-height: normal;
	letter-spacing: 1px;
}

@media screen and (max-width: 900px) {
	.my-application-header-address p {
		line-height: 0px;
	}
}

.my-application-header-subject3 {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	padding: 0 2%;
	margin-top: 1%;
}

.my-application-subject3 {
	width: 100%;
	display: flex;
}

.my-application-subject3-1 {
	width: 58%;
	border-bottom: 1px solid #000;
	padding: 0;
	margin: 0;
}

.my-application-subject3-2 {
	width: 7%;
	font-weight: 500;
	font-size: 1.3vw;
	letter-spacing: 1px;
	margin-right: 1%;
}

.my-application-subject3-3 {
	width: 5%;
	font-weight: 500;
	font-size: 1.3vw;
	letter-spacing: 1px;
}

.my-application-subject3-4 {
	width: 30%;
	border-bottom: 1px solid #000;
}

.my-application-subject3 h4 {
	font-size: 1.3vw;
	letter-spacing: 1px;
}

.my-application-header-subject2 {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	padding: 0 2%;
	margin-top: 3%;
}

.my-application-subject2 {
	margin-left: 10%;
}

.my-application-subject2 h4 {
	font-size: 1.3vw;
	letter-spacing: 1px;
}

.my-application-subject2-title {
	width: 20%;
}

.my-application-subject2-title h4 {
	font-size: 1.3vw;
	letter-spacing: 1px;
	font-weight: 700;
	text-decoration: underline;
}

.my-application-header-subject {
	width: 100%;
	box-sizing: border-box;
	margin-top: 5%;
	display: flex;
	padding: 0 2%;
}

.my-application-subject {
	margin-left: 20%;
}

.my-application-subject h4 {
	font-size: 1.5vw;
	font-weight: 700;
	text-decoration: underline;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.my-application-subject-title {
	width: 20%;
}

.my-application-subject-title h4 {
	font-size: 1.3vw;
	letter-spacing: 1px;
}

.my-application-header-logo {
	width: 50%;
	box-sizing: border-box;
	padding: 0 2%;
}

.my-application-header-title {
	width: 50%;
	box-sizing: border-box;
	text-align: center;
	padding: 0 2%;
}

.my-application-header-title-full {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	padding: 0 2%;
}

.my-application-header-title-full h4 {
	font-size: 1.7vw;
	line-height: 1.6;
	letter-spacing: 1px;
	font-weight: 500;
}

.display-flex {
	display: flex;
	width: 100%;
	margin-bottom: 5%;
	justify-content: space-between;
}

.my-main-logo {
	width: 7vw;
}

.challan {
	width: 100%;
}

.qr_code_image {
	width: 5vw;
}

.my-main-logo2 {
	width: 20vw;
}

.my-application-header-title h4 {
	font-size: 1.5vw;
	font-weight: bold;
	line-height: 1.6;
	letter-spacing: 1px;
}

.my-application-header-ref {
	width: 50%;
	box-sizing: border-box;
	text-align: right;
	padding: 0 2%;
}

.my-application-header-ref h4 {
	font-size: 1.3vw;
	font-weight: bold;
	letter-spacing: 1px;
}

.my-application-header-middle {
	width: 100%;
	margin: 2% 0;
	padding: 1% 0;
	box-sizing: border-box;
	text-align: center;
	border: 1px solid #000;
}

.my-application-header-middle-title h4 {
	font-weight: bold;
	font-size: 1.5vw;
}

.my-application-header-middle2 {
	width: 100%;
	margin: 1% 0;
	padding: 1% 1%;
	box-sizing: border-box;
	border: 1px solid #000;
}

.my-application-header-middle2-title h4 {
	font-weight: bold;
	font-size: 1.7vw;
}

.my-application-item-header {
	width: 100%;
	margin: 0;
	padding: 0.5% 1%;
	box-sizing: border-box;
	border: 1px solid #000;
}

.my-application-item-header-title h4 {
	font-weight: bold;
	font-size: 1.5vw;
}

.my-application-item {
	width: 100%;
	margin: 0;
	padding: 0 1%;
	box-sizing: border-box;
	border-left: 1px solid #000;
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
	display: flex;
}

.my-application-item-key {
	width: 45%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding: 0.5% 1%;
}

.my-application-item-key h4 {
	font-weight: bold;
	font-size: 1.3vw;
}

.my-application-item-col1 {
	width: 10%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding-left: 1%;
	padding-right: 1%;
	padding-bottom: 1%;
	padding-top: 0.5%;
	text-align: center;
}

.my-application-item-col1 h4 {
	font-weight: bold;
	font-size: 1vw;
}

.my-application-item-col1 p {
	font-weight: bold;
	font-size: 0.9vw;
}

.my-application-item-col2 {
	width: 50%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding-left: 1%;
	padding-right: 1%;
	padding-bottom: 1%;
	padding-top: 0.5%;
}

.my-application-item-col2 h4 {
	font-weight: bold;
	font-size: 1vw;
	text-align: center;
}

.my-application-item-col2 p {
	font-weight: bold;
	font-size: 0.9vw;
}

.my-application-item-col3 {
	width: 10%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding: 0.5% 1%;
	text-align: center;
}

.my-application-item-col3 h4 {
	font-weight: bold;
	font-size: 1vw;
}

.my-application-item-col4 {
	width: 10%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding: 0.5% 1%;
	text-align: center;
}

.my-application-item-col4 h4 {
	font-weight: bold;
	font-size: 1vw;
}

.my-application-item-col5 {
	width: 20%;
	box-sizing: border-box;
	padding: 0.5% 1%;
	text-align: center;
}

.my-application-item-col5 h4 {
	font-weight: bold;
	font-size: 1vw;
}

.my-application-item-value {
	width: 55%;
	box-sizing: border-box;
	padding: 0.5% 1%;
}

.my-application-item-value h4 {
	font-size: 1.3vw;
}

.my-application-header2 {
	width: 100%;
	margin: 10% 0;
	box-sizing: border-box;
	text-align: center;
}

.my-application-header2-title h4 {
	font-weight: bold;
	font-size: 1.3vw;
}

.my-application-body {
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 10%;
	padding: 0 2%;
}

.my-application-body-title {
	font-size: 1vw;
}

.my-application-body-text p {
	text-indent: 10%;
	text-align: justify;
	text-justify: inter-word;
	line-height: 1.9;
	font-size: 1vw;
	letter-spacing: 1px;
}

.my-application-footer1 {
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 10%;
	text-align: right;
	padding: 0 2%;
	display: flex;
}

.my-application-footer1-left {
	width: 50%;
	box-sizing: border-box;
	text-align: left;
}

.my-application-footer1-left p {
	font-size: 1vw;
	letter-spacing: 1px;
}

.my-application-footer1-right {
	width: 50%;
	box-sizing: border-box;
	text-align: center;
}

.my-application-footer1-right2 {
	width: 50%;
	box-sizing: border-box;
	text-align: center;
	display: flex;
	justify-content: right;
	align-items: center;
}

.my-application-footer1-right2 h4 {
	font-weight: bold;
	font-size: 1vw;
	letter-spacing: 1px;
}

.my-application-footer1-left2 {
	width: 50%;
	box-sizing: border-box;
	text-align: left;
}

.my-application-footer1-left2 h4 {
	font-weight: bold;
	font-size: 1vw;
	letter-spacing: 1px;
}

.my-application-footer1-right h4 {
	font-weight: bold;
	font-size: 1vw;
	letter-spacing: 1px;
}

.my-application-footer1-right p {
	font-size: 1vw;
	letter-spacing: 1px;
}

.my-application-footer2 {
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 10%;
	padding: 0 2%;
}

@media screen and (max-width: 900px) {
	.my-application-footer2 p {
		line-height: normal;
	}
}

.my-application-footer2 p {
	line-height: normal;
	font-size: 1vw;
	margin: 0;
	padding: 0;
	letter-spacing: 1px;
}

.proforma-left-70 {
	width: 70%;
}

.proforma-right-30 {
	width: 30%;
}

.freeship-col1 {
	width: 5%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding: 0.5% 1%;
	text-align: center;
}

.freeship-col1 h4 {
	font-weight: bold;
	font-size: 1vw;
}

.freeship-col1 p {
	font-weight: bold;
	font-size: 0.9vw;
}

.freeship-col2 {
	width: 25%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding: 0.5% 1%;
}

.freeship-col2 h4 {
	font-weight: bold;
	font-size: 1vw;
	text-align: center;
}

.freeship-col2 p {
	font-weight: bold;
	font-size: 0.9vw;
}

.freeship-col3 {
	width: 25%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding: 0.5% 1%;
	text-align: center;
}

.freeship-col3 h4 {
	font-weight: bold;
	font-size: 1vw;
}

.freeship-col4 {
	width: 15%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding: 0.5% 1%;
	text-align: center;
}

.freeship-col4 h4 {
	font-weight: bold;
	font-size: 1vw;
}

.freeship-col5 {
	width: 15%;
	box-sizing: border-box;
	border-right: 1px solid #000;
	padding: 0.5% 1%;
	text-align: center;
}

.freeship-col5 h4 {
	font-weight: bold;
	font-size: 1vw;
}

.freeship-col6 {
	width: 15%;
	box-sizing: border-box;
	padding: 0.5% 1%;
	text-align: center;
}

.freeship-col6 h4 {
	font-weight: bold;
	font-size: 1vw;
}